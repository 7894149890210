<template>
  <div id="visit-us">
    <h1 style="font-weight: bold; color: white">About Us
      <b-avatar
              href="https://www.thuisbezorgd.nl/batavia-amsterdam"
              size="0.7em"
              variant="black"
              src="https://i.imgur.com/F4gFoR6.png"
              target="_blank"
              style="margin-left: 0.3em"
      ></b-avatar>
      <b-avatar
              href="https://www.ubereats.com/nl/amsterdam/food-delivery/batavia-amsterdam/Ib1xWE6CSyyeIFVWm9kGlQ"
              size="0.7em"
              variant="black"
              src="https://i.imgur.com/qjcDRVr.png"
              target="_blank"
              style="margin-left: 0.3em"
      ></b-avatar>
    </h1>
    <h6 style="color: #CBA661; font-weight: bold; letter-spacing: 10px">Batavia Amsterdam</h6>
    <b-card-group>
      <b-card class="card-background col-3 no-border" style="height: 0px">
      </b-card>
      <b-card class="card-background col-sm-6 col-md-6 no-border">
        <b-card-text style="color: white">
          <p>
            As a company we recognize our responsibilities to society, employees, and their family.<br><br>

            We aspire to deliver the best quality products to our customer. We take our responsibility as a growing company seriously. We strive in delivering delicious, tasty, ang high quality food.<br><br>

            It is our top priority to ensure that the ingredients that we use are safe, good quality and that those materials are sourced responsibly and sustainably.<br><br>

            Furthermore, as a global citizen we also recognize our social responsibility to our society. With that, we are looking to give back some our profits to help to improve our environments and help those are in needs.
          </p>
        </b-card-text>
      </b-card>
      <b-card class="card-background col-3 no-border" style="height: 0em"></b-card>
    </b-card-group>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About Us',
    titleTemplate: '%s ← Batavia Amsterdam',
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'We aspire to deliver the best quality products to our customer'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.bataviamsterdam.com/about-us'}
    ]
  }
}
</script>

<style scoped>
  .card-background {
    background-color: #121521;
  }

  .no-border {
    border: none;
  }
</style>

<style scoped>
  @media only screen and (max-device-width: 568px) {
    #visit-us {
      margin-bottom: 1em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation: portrait) {
    #visit-us {
      margin-bottom: 8em;
    }
  }

  @media only screen and (min-device-width: 569px) and (orientation: landscape) {
    #visit-us {
      margin-bottom: 7em;
    }
  }
</style>